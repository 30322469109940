<template>
  <div>
    <EditorFrame v-if="hb" :hb="hb" ref="frame">
      <template>
        <VerseEditor v-if="/Verse/.test(hb.contentType)" :hb="hb" @change="contentChange" />
        <div v-else-if="/Intensive/.test(hb.contentType)">
          <IntensiveEditor :hb="hb" />
        </div>
        <ViewEditor v-else :hb="hb" @change="contentChange" />
      </template>
      <template v-slot:buttons>
        <Button class="center-block-btn" type="primary" @click="postSubmit(0)">确认无误，通过审核</Button>
        <Button class="center-block-btn" @click="postSubmit(1)" type="error">有问题，不过审</Button>
      </template>
      <template v-slot:right-side>
        <Tabs :value="'name2'">
          <TabPane label="编辑状态" name="name2">
            <EditStatus ref="editStatus" :hb="hb" />
          </TabPane>
          <TabPane label="版本记录" name="name1" v-if="hb.status!='0'">
            <colophon :taskInfo="taskInfo"></colophon>
          </TabPane>
        </Tabs>
      </template>
    </EditorFrame>
  </div>
</template>

<script >
import lingYunApi from "../api/api";
import validateForm from "./components-for-editor/validateForm";
import homeService from "@/api/homePageService";
import { getHbTaskView } from "../api/hbService";
import editorMixin from "./editorMixin";
export default {
  data() {
    return {
      hb: null,
      taskInfo: {},
      isDisable: false // 开关
    };
  },
  mounted() {
    this.currentIndex = 0;
    this.initTaskView();
  },
  methods: {
    contentChange() {
      this.$eventBus.$emit("hbChange");
      this.$refs.editStatus.refresh();
    },

    //获取任务详情
    initTaskView() {
      let { taskId, processId, bizId, contentType } = this.$route.query;
      let params = { taskId, processId, bizId, contentType };
      this.$Spin.show();
      getHbTaskView(params, () => {
        this.$Spin.hide();
      }).then(res => {
        this.hb = res.content;
        if (res.content) {
          this.hb.taskName = res.taskName;
        }
        this.taskInfo = res.taskInfo;
      });
    },

    async postSubmit(e) {
      let message =
        this.$refs.frame.validateBaseInfo() || (await validateForm(this.hb));
      if (message) {
        this.$Message.warning(message);
        return;
      }
      let msg = this.$Message.loading({
        content: "提交中....",
        duration: 0
      });
      this.isDisable = true;
      let hb = this.hb;
      hb.pass = e;
      hb.audioContentList = null;
      lingYunApi
        .postSubmit(hb)
        .then(res => {
          msg();
          this.isDisable = false;
          this.isDropTask = false;
          this.$Message.success("提交成功");
          setTimeout(() => {
            this.$router.back();
          }, 1000);
        })
        .catch(err => {
          msg();
          this.isDisable = false;
          this.$Message.warning(err);
        });
    }
  },
  mixins: [editorMixin]
};
</script>

<style scoped>
::v-deep .ivu-tabs {
  width: 600px;
}
</style>
